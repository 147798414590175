import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
function RequestAccessAcknowledgePopup({ open, handleClose }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "12px", width: "25%" } }}
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Thank you! Someone will get in touch shortly
        </DialogContentText>
      </DialogContent>
      <DialogActions className="!justify-center">
        <button
          type="submit"
          className="jess-button w-[100%] !rounded-md"
          onClick={() => handleClose()}
        >
          Ok
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default RequestAccessAcknowledgePopup;
