import React from "react";
import QuizIcon from "../../../../images/quiz.png";
import LessonIcon from "../../../../images/lesson-icon.png";
import CheckCircle from "../../../../images/check_circle.png";
import Lock from "../../../../images/lock.png";

function QuizOrLessonCard({
  data,
  type,
  itemIndex,
  onMouseEnter,
  onMouseLeave,
  handleSelectedIndex,
  selectedIndex,
  hoverIndex,
  loading,
  handleClickQuizReview,
}) {
  const handleHover = () => {
    if (
      (type === 1 && data.lesson_state !== 2) ||
      (type === 2 && data.quiz_state !== 1)
    ) {
      return onMouseEnter(type === 1 ? data.lesson_key : data.student_quiz_id);
    }
  };

  const handleClickButton = () => {
    handleSelectedIndex(
      type === 1 ? data.lesson_key : data.student_quiz_id,
      data,
      type
    );
    if (type === 2 && data.quiz_state === 1) {
      handleClickQuizReview(data);
    }
  };

  const isSelected =
    selectedIndex === (type === 1 ? data.lesson_key : data.student_quiz_id);

  return (
    <div
      onClick={() => !loading && !isSelected && handleClickButton()}
      onMouseEnter={() => handleHover()}
      onMouseLeave={() => onMouseLeave()}
      className="flex mt-[1%]"
      style={{
        backgroundColor:
          !isSelected &&
          hoverIndex === (type === 1 ? data.lesson_key : data.student_quiz_id)
            ? "#4F355F"
            : isSelected
            ? "#6A3C78"
            : "",
      }}
    >
      <div
        className="bg-[white] w-[1%]"
        style={{
          display: isSelected ? "block" : "none",
        }}
      ></div>
      <button
        disabled={loading || isSelected}
        className={`flex w-[100%] pb-[8px] pt-[8px] ml-[5%] ${
          loading || isSelected ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        <div>
          <img
            src={
              type === 1 && data.lesson_state === 0
                ? QuizIcon
                : type === 1 && data.lesson_state === 1
                ? CheckCircle
                : type === 1 && data.lesson_state === 2
                ? Lock
                : type === 2 && data.quiz_state === 1
                ? CheckCircle
                : LessonIcon
            }
            alt={type === 1 ? "Lesson Icon" : "Quiz Icon"}
          />
        </div>
        <div className="ml-[4%] flex-grow">
          <div className="flex justify-between items-center">
            <div>
              <div className="lesson-or-quiz-text text-start">
                {type === 1 ? data.lesson_name : data.student_quiz_title}
              </div>
              <div className="lesson-or-quiz-sub-text text-start">
                {type === 1 ? "Lesson" : "Retrieval Quiz"}
              </div>
            </div>
            {loading && type === 1 && isSelected && (
              <div className="mr-2">
                <div className="w-6 h-6 border-4 border-t-transparent border-white border-solid rounded-full animate-spin"></div>
              </div>
            )}
          </div>
        </div>
      </button>
    </div>
  );
}

export default QuizOrLessonCard;
