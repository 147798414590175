import React, { useState } from "react";
import Info from "../../../images/info.png";
import Close from "../../../images/close.png";
import Tick from "../../../images/check.png";

import QuizResult from "./QuizResult";
import ChunkProgressBar from "./ChunkProgressBar";

function QuizCard({
  quizData,
  selectedQuizQuestionIterationIndex,
  handleIterationIndex,
  selectedAnswerIndex,
  handleSelectedAnswer,
  isSubmitted,
  handleSubmittedStatus,
  quizCompleted,
  quizScore,
  quizTotalMarks,
  submitQuiz,
  quizType,
  quizMarksReview,
  nextQuiz_id,
  nextQuiz,
  handleSelectedIndex,
  currentIndex,
  handleClickQuizReview,
  sidebarData,
  setNextQuiz,
}) {
  const handleSubmit = () => {
    handleSubmittedStatus(true);
  };

  console.log("IS SUBMITTED STATUS: ", isSubmitted);

  const [options] = useState([
    { label: "A" },
    { label: "B" },
    { label: "C" },
    { label: "D" },
    { label: "E" },
    { label: "F" },
    { label: "G" },
    { label: "H" },
    { label: "I" },
    { label: "J" },
    { label: "K" },
    { label: "L" },
    { label: "M" },
    { label: "N" },
    { label: "O" },
    { label: "P" },
    { label: "Q" },
    { label: "R" },
    { label: "S" },
    { label: "T" },
  ]);

  return (
    <div className="w-[45%] mb-[5%]">
      {quizData !== undefined && !quizCompleted ? (
        <div>
          <div className="mb-[5%]">
            <ChunkProgressBar
              currentQuestionIndex={selectedQuizQuestionIterationIndex + 1}
              totalQuestions={quizData.length}
            />
          </div>
          <div id="questionIteration" className="question-iteration mb-[3.5%]">
            {`Question ${selectedQuizQuestionIterationIndex + 1} of ${
              quizData.length
            }`}
          </div>

          <div
            id="questionText"
            className="question-text"
            dangerouslySetInnerHTML={{
              __html:
                quizData[selectedQuizQuestionIterationIndex].quiz_question,
            }}
          ></div>
          <div
            id="instructions"
            className="flex mt-[3%]"
            style={{ display: isSubmitted && "none" }}
          >
            <img src={Info} alt="" />
            <div className="ml-[1%] instruction-text">
              Tap the correct answer
            </div>
          </div>

          <div id="optionButtons" className="mt-[1%]">
            {quizData[selectedQuizQuestionIterationIndex].answers.map(
              (answer, index) => (
                <button
                  disabled={isSubmitted}
                  style={{
                    border:
                      selectedAnswerIndex !== -1 &&
                      index === selectedAnswerIndex &&
                      "1.5px solid #7E418B",
                    lineHeight: "normal",
                  }}
                  className="option-buttons"
                  onClick={() => handleSelectedAnswer(index)}
                >
                  {`${options[index].label}. ` + answer.quiz_option_text}
                </button>
              )
            )}
          </div>
          <div id="submit" className="mt-[6%]">
            <button
              style={{ display: isSubmitted && "none" }}
              disabled={selectedAnswerIndex === -1}
              onClick={() =>
                isSubmitted ? handleIterationIndex() : handleSubmit()
              }
              className={
                selectedAnswerIndex === -1
                  ? "disabled-submit-button"
                  : "enabled-submit-button"
              }
            >
              {"Submit"}
            </button>
          </div>

          {isSubmitted && (
            <div className="flex ">
              <div
                style={{
                  padding: "10px",
                  border:
                    quizData[selectedQuizQuestionIterationIndex].answers[
                      selectedAnswerIndex
                    ].quiz_options_score === 0
                      ? "1.5px solid #EB6262"
                      : "1.5px solid #74DA7F",
                }}
                className="option-buttons flex space-x-2"
              >
                <img
                  style={{ objectFit: "contain" }}
                  src={
                    quizData[selectedQuizQuestionIterationIndex].answers[
                      selectedAnswerIndex
                    ].quiz_options_score === 0
                      ? Close
                      : Tick
                  }
                  alt=""
                />
                <div className="feedback-text" style={{ lineHeight: "20px" }}>
                  {quizData[selectedQuizQuestionIterationIndex].answers[
                    selectedAnswerIndex
                  ].quiz_options_score === 0
                    ? quizData[selectedQuizQuestionIterationIndex]
                        .quiz_incorrect_feedback
                    : quizData[selectedQuizQuestionIterationIndex]
                        .quiz_correct_feedback}
                </div>
              </div>
            </div>
          )}
          {isSubmitted && (
            <div
              id="submit"
              className={`mt-[6%] ${quizType === 2 && "justify-between flex"}`}
            >
              <button
                style={{
                  display:
                    quizType === 1 || selectedQuizQuestionIterationIndex === 0
                      ? "none"
                      : "block",
                }}
                onClick={() => handleIterationIndex(-1)}
                className={
                  "enabled-submit-button !bg-[white] !text-[#7E418B] !border-[#7E418B] border-[1px]	"
                }
              >
                Back
              </button>
              <button
                onClick={() => handleIterationIndex(1)}
                className={"enabled-submit-button"}
              >
                {selectedQuizQuestionIterationIndex === quizData.length - 1
                  ? "View Result"
                  : quizType === 1
                  ? "Continue"
                  : "Next"}
              </button>
            </div>
          )}
        </div>
      ) : (
        quizCompleted && (
          <QuizResult
            quizScore={quizScore}
            quizTotalMarks={quizTotalMarks}
            submitQuiz={submitQuiz}
            quizType={quizType}
            quizMarksReview={quizMarksReview}
            nextQuiz_id={nextQuiz_id}
            nextQuiz={nextQuiz}
            handleSelectedIndex={handleSelectedIndex}
            currentIndex={currentIndex}
            handleClickQuizReview={handleClickQuizReview}
            sidebarData={sidebarData}
            setNextQuiz={setNextQuiz}
          />
        )
      )}
    </div>
  );
}

export default QuizCard;
