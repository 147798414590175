import React, { useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import SubtopicArrowIcon from "../../../../images/arrow_down_subtopic.png";
import SubtopicArrowUpIcon from "../../../../images/arrow_up_subtopic.png";
import QuizOrLessonCard from "./QuizOrLessonCard";

export function SidebarSubtopicAccordian({
  subtopic,
  subtopicIndex,
  handleMouseEnterTopic,
  handleLessonClick,
  handleQuizClick,
  selectedIndexQuizOrLesson,
  hoverIndexQuizOrLesson,
  onMouseEnter,
  onMouseLeave,
  handleSelectedIndex,
  openSubtopicAccordian,
  setOpenSubtopicAccordian,
  handleClickQuizReview,
  topicIndex,
  setNextQuiz,
}) {
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [loading, setLoading] = useState(false);

  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(-1);
  };

  const handleOpen = (value) => {
    if (openSubtopicAccordian === value) {
      setOpenSubtopicAccordian(null);
    } else {
      setOpenSubtopicAccordian(value);
    }
  };

  const handleSelectedIndexWrapper = (id, data, type) => {
    if (type === 1) {
      setLoading(true);
    }

    handleSelectedIndex(id, data, type);
    const index = subtopic.quizzes_list.findIndex(
      (quiz) => quiz.student_quiz_id === id
    );

    if (index !== -1 && index + 1 < subtopic.quizzes_list.length) {
      const quizToSet = subtopic.quizzes_list[index + 1];
      setNextQuiz(quizToSet);
    } else {
      console.log("No next quiz available or quiz not found.");
      setNextQuiz(null); // or handle this case as appropriate
    }
    if (type === 2 && data.quiz_state === 1) {
      handleClickQuizReview(data);
    }
    setTimeout(() => {
      if (type === 1) {
        setLoading(false);
      }
    }, 2000); // Adjust the delay as needed
  };

  return (
    <>
      <Accordion open={openSubtopicAccordian === subtopicIndex}>
        <div className="mr-[3px] ml-[3px] ">
          <AccordionHeader
            onMouseEnter={() => handleMouseEnter(subtopicIndex)}
            onMouseLeave={() => handleMouseLeave()}
            onClick={() => handleOpen(subtopicIndex)}
            className=" p-[12px] justify-between accordian-header-text border-b-0 rounded mt-[5px] mb-[5px] pl-[20px]"
            style={{
              color: "white",
              backgroundColor:
                openSubtopicAccordian !== subtopicIndex &&
                hoverIndex === subtopicIndex &&
                "#4F355F",
            }}
          >
            <div className="flex">
              <div className="mt-[auto] mb-[auto]">
                <img
                  src={
                    openSubtopicAccordian === subtopicIndex
                      ? SubtopicArrowUpIcon
                      : SubtopicArrowIcon
                  }
                />
              </div>
              <div className="ml-[10px] text-start">
                {`${topicIndex + 1}.${subtopicIndex + 1} ${
                  subtopic.sub_topic_name
                }`}
              </div>
            </div>
          </AccordionHeader>
        </div>

        <hr className="mt-0 mb-0" />
        <AccordionBody
          className="p-0 justify-between"
          style={{
            color: "white",
          }}
        >
          {subtopic.lesson_list.map((lesson, index) => (
            <QuizOrLessonCard
              key={`lesson-${index}`} // Ensure unique keys
              data={lesson}
              type={1}
              itemIndex={index}
              selectedIndex={selectedIndexQuizOrLesson}
              hoverIndex={hoverIndexQuizOrLesson}
              handleClick={handleLessonClick}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              handleSelectedIndex={handleSelectedIndexWrapper}
              loading={loading}
            />
          ))}
          {subtopic.quizzes_list &&
            subtopic.quizzes_list.map((quiz, index) => (
              <QuizOrLessonCard
                key={`quiz-${index}`} // Ensure unique keys
                data={quiz}
                type={2}
                itemIndex={index + subtopic.lesson_list.length}
                handleClick={handleQuizClick}
                selectedIndex={selectedIndexQuizOrLesson}
                hoverIndex={hoverIndexQuizOrLesson}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                handleSelectedIndex={handleSelectedIndexWrapper}
                handleClickQuizReview={handleClickQuizReview}
                loading={loading}
              />
            ))}
        </AccordionBody>
      </Accordion>
    </>
  );
}
