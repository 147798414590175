import React from "react";
import { Progress } from "rsuite";
import "rsuite/dist/rsuite.min.css";
function QuizResult({
  quizScore,
  quizTotalMarks,
  submitQuiz,
  quizType,
  quizMarksReview,
  nextQuiz_id,
  nextQuiz,
  handleSelectedIndex,
  currentIndex,
  handleClickQuizReview,
  sidebarData,
  setNextQuiz,
}) {
  const handleStrokeColor = () => {
    let percentage =
      ((quizType === 1 ? quizScore : quizMarksReview) / quizTotalMarks) * 100;
    if (percentage < 50) {
      return "red";
    } else if (percentage >= 50 && percentage <= 70) {
      return "#F6CA30";
    } else if (percentage > 70) {
      return "green";
    }
  };
  const extractQuizzes = (sidebar) => {
    let allQuizzes = [];

    sidebar?.topic_list.forEach((topic) => {
      topic.sub_topic_list.forEach((subtopic) => {
        allQuizzes = [...allQuizzes, ...subtopic.quizzes_list];
      });
    });

    return allQuizzes;
  };
  const handleNextQuiz = () => {
    let quizzes = extractQuizzes(sidebarData);
    handleSelectedIndex(nextQuiz.student_quiz_id, nextQuiz, 2);
    if (nextQuiz.quiz_state === 1) {
      handleClickQuizReview(nextQuiz);
    }
    const currentQuizIndex = quizzes.findIndex(
      (quiz) => quiz.student_quiz_id === nextQuiz.student_quiz_id
    );

    if (currentQuizIndex !== -1 && currentQuizIndex + 1 < quizzes.length) {
      const nextQuizToSet = quizzes[currentQuizIndex + 1];
      setNextQuiz(nextQuizToSet);
    } else {
      console.log("No next quiz available or end of quiz list reached.");
      setNextQuiz(null); // or handle the end of the quiz list appropriately
    }

    // Additional check if the quiz state is 1 (perhaps meaning it needs review)
  };

  const style = {
    width: 250,
    display: "inline-block",
    marginRight: 20,
    marginLeft: 20,
    position: "relative",
  };
  return (
    <div className="text-center">
      <div style={style}>
        <Progress.Circle
          classPrefix="progress"
          gapDegree={60}
          strokeLinecap="butt"
          percent={
            ((quizType === 1 ? quizScore : quizMarksReview) / quizTotalMarks) *
            100
          }
          trailColor="#E1DAE3A8"
          trailWidth={5}
          strokeWidth={5}
          gapPosition="bottom"
          strokeColor={handleStrokeColor()}
          showInfo={false}
        />
        <div className="rs-progress-circle-info" style={{ top: "45px" }}>
          <div className="score-text">{`${
            quizType === 1 ? quizScore : quizMarksReview
          }/${quizTotalMarks}`}</div>
          <div className="score-sub-text mt-[8%]">Score</div>
        </div>
      </div>
      <div>
        <div className="quiz-completed-text text-center mt-[2%]">
          Quiz Completed!
        </div>
        <div className="quiz-completed-sub-text mt-[3%]">
          Every quiz takes you closer to mastery. Keep going!
        </div>
        <button
          style={{ display: nextQuiz ? "" : "none" }}
          onClick={handleNextQuiz}
          className="enabled-submit-button mt-[3%]"
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default QuizResult;
