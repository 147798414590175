import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

function RequestAccessDialogue({
  open,
  handleClose,
  earlyAccessMail,
  earlyAccessError,
  earlyAccessOnChange,
  validateEarlyAccessMail,
  requestEarlyAccessOnClick,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "12px", width: "25%" } }}
    >
      <DialogContent>
        <div>
          {/* <label className="email-label">Enter your Email</label> */}
          <input
            type="email"
            name="email"
            style={{ height: "50px" }}
            className="textfield-text border-[1px] border-[#8B8B90] rounded-xl w-full px-4 text-black leading-tight  "
            id="username"
            placeholder="Enter your Email"
            value={earlyAccessMail}
            onChange={earlyAccessOnChange}
            onBlur={validateEarlyAccessMail}
          />
          <div className="error-text mt-[10px]">{earlyAccessError}</div>
        </div>
      </DialogContent>
      <DialogActions className="!justify-center">
        <button
          type="submit"
          className="jess-button w-[60%] !rounded-md"
          onClick={() => requestEarlyAccessOnClick()}
        >
          Request Access
        </button>
        <button
          style={{
            boxShadow:
              "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
          }}
          onClick={handleClose}
          className="disabled-submit-button w-[30%] !rounded-md"
        >
          Cancel
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default RequestAccessDialogue;
