import React, { useEffect, useRef, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import QuizCard from "./QuizCard";
import axios from "axios";
import { SidebarTopicAccordian } from "./SidebarAccordians/SidebarTopicAccordian";
import LessonCard from "./LessonCard";
import DrawerIcon from "../../../images/drawer.png";
import LockedLessonCard from "./LockedLessonCard";
import ClassIcon from "../../../images/subject content.png";
import HomeButton from "../../../images/home-button.png";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import appContext from "../../../context/appContext";
import BouncingDotsLoader from "./BouncingDotsLoader";

const drawerWidth = 400;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function ClassroomHomeDrawer({
  quizzesData,
  handleQuizValue,
  quizData,
  selectedQuizQuestionIterationIndex,
  handleIterationIndex,
  selectedAnswerIndex,
  handleSelectedAnswer,
  isSubmitted,
  handleSubmittedStatus,
  quizCompleted,
  quizScore,
  handleQuizMarks,
  quizTotalMarks,
  handleQuizTotalMarks,
  sidebarData,
  classId,
  attemptedQuiz,
  selectedQuiz,
  setSelectedQuiz,
  submitQuiz,
  handleNewQuizState,
  getSidebarData,
  showButton,
  setShowButton,
  handleQuizReviewedState,
  setQuizType,
  quizType,
  quizMarksReview,
  quizId,
  setIsSubmitted,
  isLoading,
}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [quizOrLesson, setQuizOrLesson] = React.useState(-1);
  const [lessonResponseData, setLessonResponseData] = React.useState();
  const [selectedResponseButtons, setSelectedResponseButtons] = React.useState([]);
  const [lessondata, setLessonData] = useState([]);
  const [selectedIndexQuizOrLesson, setSelectedIndexQuizOrLesson] = useState("");
  const [hoverIndexQuizOrLesson, setHoverIndexQuizOrLesson] = useState("");
  const [openAccordian, setOpenAccordian] = React.useState(null);
  const [openSubtopicAccordian, setOpenSubtopicAccordian] = React.useState(0);
  const [firstIteration, setFirstIteration] = useState(false);
  const [isLockedLesson, setIsLockedLesson] = useState(false);
  const [lockedLessonData, setLockedLessonData] = useState();
  const [currentWordIndex, setCurrentWordIndex] = useState(-5);
  const [speaking, setSpeaking] = useState(false);
  const [audio, setAudio] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lessonChanged, setLessonChanged] = useState(false);
  const [reviewedQuiz, setReviewedQuiz] = useState([]);
  const [nextQuiz, setNextQuiz] = useState([]);
  const nextQuiz_id = useRef();
  const state = useContext(appContext);

  const navigate = useNavigate();

  const handleOnClick = (data, type) => {
    if (type === 1 && data.lesson_state !== 2) {
      handleLessonClick(data);
    } else if (type === 2) {
      handleQuizClick(data);
    } else if (type === 1 && data.lesson_state === 2) {
      handleLockedLessonClick(data);
    }
  };

  const onMouseEnter = (index) => {
    setHoverIndexQuizOrLesson(index);
  };
  const onMouseLeave = () => {
    setHoverIndexQuizOrLesson(-1);
  };

  const handleSelectedIndex = (index, data, type) => {
    window.speechSynthesis.cancel();
    setSelectedIndexQuizOrLesson(index);
    handleOnClick(data, type);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleQuizClick = (quiz) => {
    if (selectedQuiz && selectedQuiz.student_quiz_id === quiz.student_quiz_id) {
      return;
    }
    if (state.audio) {
      state.audio.pause();
    }
    // state.audio.currentTime = 0;
    setSelectedQuiz(quiz);
    const token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/student_get_quiz", {
        method: "POST",
        student_quiz_id: quiz.student_quiz_id,
      })
      .then((res) => {
        console.log("Quiz: ", res.data);
        setQuizType(1);
        setQuizOrLesson(0);
        handleQuizValue(res.data.quiz_question_list);
        handleQuizTotalMarks(quiz.quiz_marks);
        nextQuiz_id.current = quiz.student_quiz_id;
        handleNewQuizState();
        setIsLockedLesson(false);
        setSpeaking(false);
        setCurrentWordIndex(-5);
        if (state.audio) {
          state.audio.pause();
        }
        // audio.pause();
        // audio.currentTime = 0;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleClickQuizReview = (quiz) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/review_quiz_jess", {
        method: "POST",
        class_id: classId,
        student_quiz_id: quiz.student_quiz_id,
      })
      .then((res) => {
        console.log("DATA IS: ", res.data.data);
        setQuizOrLesson(0);
        setReviewedQuiz(res.data.data);
        handleQuizValue(res.data.data);
        handleQuizTotalMarks(quiz.quiz_marks);
        handleQuizReviewedState(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleLessonClick = (lesson) => {
    // setShowButton(false);
    setQuizOrLesson(-1);
    setSelectedQuiz(null);
    setSpeaking(false);
    setCurrentWordIndex(-5);
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_lesson_for_user", {
        method: "POST",
        lesson_key: lesson.lesson_key,
      })
      .then((res) => {
        addOrUpdateLessonIdWithIndex(res.data.lesson_data[0].lesson_id, getCurrentIndexByLessonId(res.data.lesson_data[0].lesson_id));
        setQuizOrLesson(1);
        setLessonData(res.data.lesson_data);
        setSelectedQuiz(null);
        console.log("DATA", res.data.lesson_data);
        setLessonResponseData(res.data.lesson_data[0].page_list);
        setSelectedResponseButtons(
          res.data.lesson_data[0].page_list[
            isEmailAllowed(localStorage.getItem("email_address")) ? getCurrentIndexByLessonId(res.data.lesson_data[0].lesson_id) : 0
          ].options_list
        );
        setIsLockedLesson(false);
        setSpeaking(false);
        setCurrentWordIndex(-5);
        setCurrentIndex(isEmailAllowed(localStorage.getItem("email_address")) ? getCurrentIndexByLessonId(res.data.lesson_data[0].lesson_id) : 0);
        setLessonChanged(true);
        if (state.audio) {
          state.audio.pause();
        }
        state.audio.currentTime = 0;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function addOrUpdateLessonIdWithIndex(lessonId, currentIndex) {
    let items = JSON.parse(localStorage.getItem("items")) || [];
    const index = items.findIndex((item) => item.id === lessonId);
    if (index === -1) {
      items.push({ id: lessonId, currentIndex: currentIndex });
      console.log("Object added:", { id: lessonId, currentIndex: currentIndex });
    } else {
      items[index].currentIndex = currentIndex;
      console.log("Object updated:", { id: lessonId, currentIndex: currentIndex });
    }

    localStorage.setItem("items", JSON.stringify(items));
  }

  function getCurrentIndexByLessonId(lessonId) {
    let items = JSON.parse(localStorage.getItem("items")) || [];
    const lesson = items.find((item) => item.id === lessonId);
    if (lesson) {
      return parseInt(lesson.currentIndex);
    } else {
      console.log("Lesson not found with id:", lessonId);
      return 0;
    }
  }

  function isEmailAllowed(email) {
    const allowedEmails = process.env.REACT_APP_ALLOWED_EMAILS.split(",");

    // Check if the provided email is in the list of allowed emails
    return allowedEmails.includes(email);
  }

  const handleLockedLessonClick = (lesson) => {
    if (state.audio) {
      state.audio.pause();
      state.audio.currentTime = 0;
    }

    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_lesson_date", {
        method: "POST",
        area_id: lesson.area_id,
        class_id: classId,
      })
      .then((res) => {
        setLockedLessonData(res.data);
        setQuizOrLesson(-1);
        setIsLockedLesson(true);
        setSelectedQuiz(null);
        setSpeaking(false);
        setCurrentWordIndex(-5);
        if (state.audio) {
          state.audio.pause();
          state.audio.currentTime = 0;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!firstIteration) {
      if (quizId) {
        // Loop through quizzes in the subtopic

        for (let topicIndex = 0; topicIndex < sidebarData.topic_list.length; topicIndex++) {
          const topic = sidebarData.topic_list[topicIndex];
          for (let subtopicIndex = 0; subtopicIndex < topic.sub_topic_list.length; subtopicIndex++) {
            const subtopic = topic.sub_topic_list[subtopicIndex];
            for (let quizIndex = 0; quizIndex < subtopic.quizzes_list.length; quizIndex++) {
              const quiz = subtopic.quizzes_list[quizIndex];
              const index = subtopic.quizzes_list.findIndex((quiz2) => quiz2.student_quiz_id === quiz.student_quiz_id);

              if (index !== -1 && index + 1 < subtopic.quizzes_list.length) {
                const quizToSet = subtopic.quizzes_list[index + 1];
                setNextQuiz(quizToSet);
              } else {
                console.log("No next quiz available or quiz not found.");
                setNextQuiz(null); // or handle this case as appropriate
              }
              if (quiz.student_quiz_id === parseInt(quizId)) {
                if (quiz.quiz_state === 1) {
                  setOpenAccordian(topicIndex);
                  handleSelectedIndex(quiz.student_quiz_id, quiz, 2);
                  setOpenSubtopicAccordian(subtopicIndex);
                  if (quiz.quiz_state === 1) {
                    handleClickQuizReview(quiz);
                  }
                }
                if (quiz.quiz_state === 0) {
                  handleQuizClick(quiz);
                  setOpenAccordian(topicIndex);
                  handleSelectedIndex(quiz.student_quiz_id, quiz, 2);
                  setOpenSubtopicAccordian(subtopicIndex);

                  setFirstIteration(true);
                  state.setLockedContent(false);
                }
                // }

                return;
              }
            }
          }
        }
      } else {
        if (sidebarData) {
          for (let topicIndex = 0; topicIndex < sidebarData.topic_list.length; topicIndex++) {
            const topic = sidebarData.topic_list[topicIndex];
            for (let subtopicIndex = 0; subtopicIndex < topic.sub_topic_list.length; subtopicIndex++) {
              const subtopic = topic.sub_topic_list[subtopicIndex];
              for (let lessonIndex = 0; lessonIndex < subtopic.lesson_list.length; lessonIndex++) {
                const lesson = subtopic.lesson_list[lessonIndex];
                if (lesson.lesson_state === 0) {
                  handleLessonClick(lesson);
                  setOpenAccordian(topicIndex);
                  handleSelectedIndex(lesson.lesson_key, lesson, 1);
                  setOpenSubtopicAccordian(subtopicIndex);
                  setFirstIteration(true);
                  state.setLockedContent(false);
                  return;
                }
              }
              if (quizOrLesson === -1) {
                for (let quizIndex = 0; quizIndex < subtopic.quizzes_list.length; quizIndex++) {
                  const quiz = subtopic.quizzes_list[quizIndex];
                  const index = subtopic.quizzes_list.findIndex((quiz2) => quiz2.student_quiz_id === quiz.student_quiz_id);

                  if (index !== -1 && index + 1 < subtopic.quizzes_list.length) {
                    const quizToSet = subtopic.quizzes_list[index + 1];
                    setNextQuiz(quizToSet);
                  } else {
                    console.log("No next quiz available or quiz not found.");
                    setNextQuiz(null); // or handle this case as appropriate
                  }
                  // Store the quiz in state if found
                  if (quiz.quiz_state === 0) {
                    handleQuizClick(quiz);
                    setOpenAccordian(topicIndex);
                    handleSelectedIndex(quiz.student_quiz_id, quiz, 2);
                    setOpenSubtopicAccordian(subtopicIndex);
                    setFirstIteration(true);
                    state.setLockedContent(false);
                    return;
                  }
                }
              }
            }
          }
        }
      }
      if (lessondata.length === 0 && quizData.length === 0) {
        state.setLockedContent(true);
      }
    }
  }, [sidebarData]);

  const handleNavigateToHome = () => {
    setSpeaking(false);
    setCurrentWordIndex(-5);
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
    window.speechSynthesis.cancel();
    navigate("/home");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{ background: "#403151" }}>
        <Toolbar style={{ background: "#403151" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" noWrap component="div">
            {/* Persistent drawer */}
          </Typography>
          <div className={` mt-[5px]`}>
            <button onClick={() => navigate("/home")}>
              <img src={HomeButton} />
            </button>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            background: "#403151",
            borderRight: "0.5px solid white",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader
          style={{
            background: "#403151",
            minHeight: "17vh",
            justifyContent: "start",
            alignItems: "start",
            padding: "7%",
            position: "sticky",
            top: "0px",
            zIndex: "1000",
          }}
        >
          <div>
            <button className="flex w-[100%]" onClick={handleDrawerClose}>
              <img src={DrawerIcon} style={{ objectFit: "contain" }} />
            </button>
            <div
              className="mt-[10%]"
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                lineHeight: "24px",
                color: "white",
              }}
            >
              <div className="flex" style={{ display: sidebarData ? "flex" : "none" }}>
                <div className="mr-[10px] ">
                  <img src={ClassIcon} />
                </div>
                <div className="flex items-center">{sidebarData && sidebarData.class_data.classroom_name}</div>
              </div>
            </div>
          </div>
          {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon style={{ color: "white" }} />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton> */}
        </DrawerHeader>
        <List style={{ background: "#403151" }}>
          {sidebarData &&
            sidebarData.topic_list.map((topic, index) => (
              <>
                <SidebarTopicAccordian
                  topic={topic}
                  topicIndex={index}
                  handleLessonClick={handleLessonClick}
                  handleQuizClick={handleQuizClick}
                  selectedIndexQuizOrLesson={selectedIndexQuizOrLesson}
                  hoverIndexQuizOrLesson={hoverIndexQuizOrLesson}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  handleSelectedIndex={handleSelectedIndex}
                  open={openAccordian}
                  setOpen={setOpenAccordian}
                  openSubtopicAccordian={openSubtopicAccordian}
                  setOpenSubtopicAccordian={setOpenSubtopicAccordian}
                  topic_list={sidebarData.topic_list}
                  handleClickQuizReview={handleClickQuizReview}
                  setNextQuiz={setNextQuiz}
                />
                <hr className="mt-0 mb-0" style={{ color: "white" }}></hr>
              </>
            ))}

          {/* {quizzesData.map((quiz, index) => (
            <ListItem key={quiz.student_quiz_id} disablePadding>
              <ListItemButton onClick={() => handleQuizClick(quiz)}>
                <ListItemIcon></ListItemIcon>
                <ListItemText
                  primary={quiz.student_quiz_title}
                  style={{ color: "white" }}
                />
              </ListItemButton>
            </ListItem>
          ))} */}
        </List>
        <Divider />
      </Drawer>
      <Main open={open} style={{ padding: "0px" }}>
        <DrawerHeader />
        <div id="quiz" className={quizOrLesson === 0 && "flex justify-center items-center pt-[6%] relative"}>
          {isLoading && (
            <div className="h-[100vh] flex items-center justify-center">
              <center>
                <BouncingDotsLoader />
              </center>
            </div>
          )}
          {quizOrLesson === 1 ? (
            <LessonCard
              lessonResponseData={lessonResponseData}
              selectedResponseButtons={selectedResponseButtons}
              setSelectedResponseButtons={setSelectedResponseButtons}
              classId={classId}
              lessondata={lessondata}
              getSidebarData={getSidebarData}
              currentWordIndex={currentWordIndex}
              setCurrentWordIndex={setCurrentWordIndex}
              speaking={speaking}
              setSpeaking={setSpeaking}
              audio={audio}
              setAudio={setAudio}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              lessonChanged={lessonChanged}
              setLessonChanged={setLessonChanged}
              showButton={showButton}
              setShowButton={setShowButton}
              handleSubmittedStatus={handleSubmittedStatus}
              addOrUpdateLessonIdWithIndex={addOrUpdateLessonIdWithIndex}
            />
          ) : quizOrLesson === 0 ? (
            <>
              <button
                className="bg-[#403151] absolute top-[2%] left-[3%]"
                style={{ borderRadius: "4px", display: "none" }}
                onClick={() => handleNavigateToHome()}
              >
                <img src={HomeButton} />
              </button>
              <QuizCard
                quizData={quizData}
                selectedQuizQuestionIterationIndex={selectedQuizQuestionIterationIndex}
                currentIndex={currentIndex}
                handleIterationIndex={handleIterationIndex}
                selectedAnswerIndex={selectedAnswerIndex}
                handleSelectedAnswer={handleSelectedAnswer}
                isSubmitted={isSubmitted}
                handleSubmittedStatus={handleSubmittedStatus}
                quizCompleted={quizCompleted}
                quizScore={quizScore}
                handleQuizMarks={handleQuizMarks}
                quizTotalMarks={quizTotalMarks}
                submitQuiz={submitQuiz}
                quizType={quizType}
                quizMarksReview={quizMarksReview}
                nextQuiz_id={nextQuiz_id}
                nextQuiz={nextQuiz}
                handleSelectedIndex={handleSelectedIndex}
                handleClickQuizReview={handleClickQuizReview}
                sidebarData={sidebarData}
                setNextQuiz={setNextQuiz}
              />
            </>
          ) : (
            isLockedLesson && (
              <div className="flex justify-center items-center h-[90vh]">
                <LockedLessonCard lockedLessonData={lockedLessonData} />
              </div>
            )
          )}
        </div>
      </Main>
    </Box>
  );
}
