import React, { useEffect, useRef, useState } from "react";
import ClassroomHomeDrawer from "./ClassroomWidgets/ClassroomHomeDrawer";
import axios from "axios";
import { useLocation } from "react-router-dom";
import moment from "moment";

function ClassroomHome() {
  const [quizzesData, setQuizzesData] = useState([]);
  const [quizData, setQuizData] = useState([]);
  const [selectedQuizQuestionIterationIndex, setSelectedQuizQuestionIterationIndex] = useState(0);

  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(-1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [quizScore, setQuizScore] = useState(0);
  const [quizTotalMarks, setQuizTotalMarks] = useState();
  const [sidebarData, setSidebarData] = useState();
  const [attemptedQuiz, setAttemptedQuiz] = useState([]);
  const [selectedQuiz, setSelectedQuiz] = useState({});
  const [showButton, setShowButton] = useState(false);
  const [quizType, setQuizType] = useState(0);
  const [loading, setLoading] = useState(true);
  const quizMarksReview = useRef(0);
  const classId = useRef("");
  const quizId = useRef("");
  const location = useLocation();
  let { class_id } = location.state !== null ? location.state : "";
  const firstIteration = useRef(0);
  useEffect(() => {
    console.log(location.search); // Log the search string
    const params = new URLSearchParams(window.location.search);
    const quizId2 = params.get("id");
    const classId2 = params.get("class_id");
    // Log the extracted parameters

    quizId.current = quizId2;
    classId.current = classId2;
    console.log("Quiz ID:", quizId.current, "Class ID:", classId.current);
  }, [location.search]);
  const handleQuizValue = (quiz) => {
    setQuizData(quiz);
  };

  const handleQuizTotalMarks = (quizTotalMarks) => {
    setQuizTotalMarks(quizTotalMarks);
  };

  const handleQuizMarks = (questionMarks, quizType) => {
    if (quizType === 2) {
      quizMarksReview.current = quizMarksReview.current + questionMarks;
    }
    let score = quizScore;
    setQuizScore(score + questionMarks);
  };

  const handleSubmittedStatus = (submitState) => {
    setIsSubmitted(submitState);
  };

  const handleSelectedAnswer = (index) => {
    setSelectedAnswerIndex(index);
  };

  const handleIterationIndex = (IterationType) => {
    setShowButton(false);
    let attemptedQuizLocal = attemptedQuiz;

    if (quizType === 1) {
      var obj = {
        student_has_question_id: quizData[selectedQuizQuestionIterationIndex].student_has_question_id,
        student_selected_option: quizData[selectedQuizQuestionIterationIndex].answers[selectedAnswerIndex].quiz_options_id,
        score: quizData[selectedQuizQuestionIterationIndex].answers[selectedAnswerIndex].quiz_options_score,
        feedback:
          quizData[selectedQuizQuestionIterationIndex].answers[selectedAnswerIndex].quiz_options_score === 0
            ? quizData[selectedQuizQuestionIterationIndex].quiz_incorrect_feedback
            : quizData[selectedQuizQuestionIterationIndex].quiz_correct_feedback,

        recall_status: quizData[selectedQuizQuestionIterationIndex].recall_status,
      };

      attemptedQuizLocal = attemptedQuizLocal.concat(obj);

      setAttemptedQuiz(attemptedQuizLocal);
    }
    if (selectedQuizQuestionIterationIndex <= quizData.length - 1) {
      if (quizType === 1) {
        handleQuizMarks(quizData[selectedQuizQuestionIterationIndex].answers[selectedAnswerIndex].quiz_options_score);
      }
    }
    let index = selectedQuizQuestionIterationIndex;
    if (selectedQuizQuestionIterationIndex < quizData.length - 1) {
      setSelectedQuizQuestionIterationIndex(++index);

      if (quizType === 2) {
        if (IterationType === -1) {
          index = index - 2;
          setSelectedQuizQuestionIterationIndex(index);
        }
        const selectedAnswerIndex = quizData[index].answers.findIndex(
          (answer) => parseInt(quizData[index].student_selected_option) === answer.quiz_options_id
        );
        setSelectedAnswerIndex(selectedAnswerIndex);
        setIsSubmitted(true);
      } else {
        setSelectedAnswerIndex(-1);
        setIsSubmitted(false);
      }
    } else {
      if (IterationType === -1) {
        index = index - 1;
        setSelectedQuizQuestionIterationIndex(index);
      } else {
        setQuizCompleted(true);
        if (quizType === 1) {
          submitQuiz(attemptedQuizLocal);
        }
      }
    }
  };

  const handleNewQuizState = () => {
    setQuizScore(0);
    setSelectedQuizQuestionIterationIndex(0);
    setSelectedAnswerIndex(-1);
    setIsSubmitted(false);
    setQuizCompleted(false);
    setAttemptedQuiz([]);
  };

  const handleQuizReviewedState = (selectedQuiz) => {
    console.log("selected quiz here", selectedQuiz);
    quizMarksReview.current = 0;
    const selectedAnswerIndex = selectedQuiz[0]?.answers.findIndex(
      (answer) => parseInt(selectedQuiz[0]?.student_selected_option) === answer.quiz_options_id
    );
    setSelectedQuizQuestionIterationIndex(0);
    setSelectedAnswerIndex(selectedAnswerIndex);
    setIsSubmitted(true);
    setQuizCompleted(false);
    setAttemptedQuiz([]);
    setQuizType(2);
    for (let index = 0; index < selectedQuiz.length; index++) {
      const question = selectedQuiz[index];
      for (let index = 0; index < question.answers.length; index++) {
        const answer = question.answers[index];
        if (parseInt(question.student_selected_option) === answer.quiz_options_id) {
          handleQuizMarks(answer.quiz_options_score, 2);
          break;
        }
      }
    }
  };

  const submitQuiz = (attemptedQuizLocal) => {
    console.log("QUIZ DATA IS: ", selectedQuiz);
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/submit_quiz", {
        method: "POST",
        classroom_id: class_id ? class_id : classId.current,
        student_quiz_id: selectedQuiz.student_quiz_id,
        total_score: selectedQuiz.quiz_marks,
        obtained_score: quizScore,
        question_response: attemptedQuizLocal,

        // lesson_key: lesson.lesson_key,
      })
      .then((res) => {
        console.log("reponse: ", res.data);
        getSidebarData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // getClasroomQuizzes();

    getSidebarData();
  }, [class_id, classId]);

  // const getClasroomQuizzes = () => {
  //   var token = "Bearer " + localStorage.getItem("access_token");
  //   axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  //   axios.defaults.headers.post["Content-Type"] =
  //     "application/json;charset=utf-8";
  //   axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  //   axios.defaults.headers.post["authorization"] = token;
  //   axios
  //     .post(process.env.REACT_APP_REST_API_BASE_URL + "/student_view_class", {
  //       method: "POST",
  //       class_id: class_id,
  //       today_date: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
  //     })
  //     .then((res) => {
  //       console.log("classroom list: ", res.data);
  //       setQuizzesData(res.data.complete_quizzes_list);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const getCurrentDate = () => {
    const date = new Date();
    const todayDate =
      date.getFullYear() +
      "-" +
      (1 + parseInt(date.getMonth())) +
      "-" +
      date.getDate() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds() +
      "." +
      date.getMilliseconds();
    console.log("object");

    return todayDate;
  };
  const getSidebarData = () => {
    var new_id = parseInt(classId.current);

    var token = "Bearer " + localStorage.getItem("access_token");
    const email = localStorage.getItem("email_address");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/view_class_jess", {
        method: "POST",
        class_id: new_id ? new_id : class_id,
        student_email: email,
        today_date: getCurrentDate(),
      })
      .then((res) => {
        console.log("Sidebar Data: ", res.data);
        let filteredData = datafiltering(res.data);
        console.log("DATA: ", filteredData);
        setSidebarData(filteredData);
        if (firstIteration.current > 1) {
          quizId.current = "";
        }
        firstIteration.current = firstIteration.current + 1;
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const datafiltering = (data) => {
    if (checkQuizzesList(data.topic_list)) {
      if (!checkQuizzesStatus(data.topic_list)) {
        if (checkLessonListWithMultipleLessons(data.topic_list)) {
          let filteredData = filterOutHierarchyWithInvalidMultipleLessons(data.topic_list);
          data.topic_list = filteredData;
          return data;
        }
        let filteredData = filterOutHierarchyWithInvalidLessons(data.topic_list);
        data.topic_list = filteredData;
        return data;
      } else {
        return data;
      }
    } else {
      if (checkLessonListWithMultipleLessons(data.topic_list)) {
        let filteredData = filterOutHierarchyWithInvalidMultipleLessons(data.topic_list);
        data.topic_list = filteredData;
        return data;
      }
      if (checkLessonList(data.topic_list) && !hasLessonWithStateOne(data.topic_list)) {
        return data;
      }
      let filteredData = filterOutHierarchyWithInvalidLessons(data.topic_list);
      data.topic_list = filteredData;
      return data;
    }
  };

  const checkQuizzesList = (data) => {
    for (let topic of data) {
      for (let subTopic of topic.sub_topic_list) {
        if (subTopic.quizzes_list.length > 0) {
          return true;
        }
      }
    }
    return false;
  };
  const checkLessonList = (data) => {
    for (let topic of data) {
      for (let subTopic of topic.sub_topic_list) {
        if (subTopic.lesson_list.length > 0) {
          return true;
        }
      }
    }
    return false;
  };

  const checkLessonListWithMultipleLessons = (data) => {
    for (let topic of data) {
      for (let subTopic of topic.sub_topic_list) {
        if (subTopic.lesson_list.length > 1) {
          return true;
        }
      }
    }
    return false;
  };

  // Function to check if any quiz has a status of null
  const checkQuizzesStatus = (data) => {
    for (let topic of data) {
      for (let subTopic of topic.sub_topic_list) {
        for (let quiz of subTopic.quizzes_list) {
          if (quiz.status !== null) {
            return true; // Return true if any quiz has status null
          }
        }
      }
    }
    return false; // Return false if no quiz has status null
  };
  const filterOutHierarchyWithInvalidLessons = (data) => {
    return data.filter((topic) => {
      // Check if any sub_topic has a lesson with lesson_state === 0
      const hasInvalidLesson = topic.sub_topic_list.some((subTopic) => subTopic.lesson_list.some((lesson) => lesson.lesson_state === 0));

      // If no invalid lessons are found in this topic, keep it
      return !hasInvalidLesson;
    });
  };
  const filterOutHierarchyWithInvalidMultipleLessons = (data) => {
    return data.map((topic) => {
      return {
        ...topic,
        sub_topic_list: topic.sub_topic_list.map((subTopic) => {
          return {
            ...subTopic,
            lesson_list: subTopic.lesson_list.filter((lesson) => lesson.lesson_state !== 0),
          };
        }),
      };
    });
  };

  const hasLessonWithStateOne = (data) => {
    return data.some((topic) => topic.sub_topic_list.some((subTopic) => subTopic.lesson_list.some((lesson) => lesson.lesson_state === 1)));
  };

  return (
    <div>
      <ClassroomHomeDrawer
        quizzesData={quizzesData}
        handleQuizValue={handleQuizValue}
        quizData={quizData}
        selectedQuizQuestionIterationIndex={selectedQuizQuestionIterationIndex}
        handleIterationIndex={handleIterationIndex}
        selectedAnswerIndex={selectedAnswerIndex}
        handleSelectedAnswer={handleSelectedAnswer}
        isSubmitted={isSubmitted}
        handleSubmittedStatus={handleSubmittedStatus}
        quizCompleted={quizCompleted}
        quizScore={quizScore}
        handleQuizMarks={handleQuizMarks}
        quizTotalMarks={quizTotalMarks}
        handleQuizTotalMarks={handleQuizTotalMarks}
        sidebarData={sidebarData}
        classId={class_id ? class_id : classId.current}
        quizId={quizId.current}
        attemptedQuiz={attemptedQuiz}
        selectedQuiz={selectedQuiz}
        setSelectedQuiz={setSelectedQuiz}
        submitQuiz={submitQuiz}
        handleNewQuizState={handleNewQuizState}
        getSidebarData={getSidebarData}
        showButton={showButton}
        setShowButton={setShowButton}
        handleQuizReviewedState={handleQuizReviewedState}
        setQuizType={setQuizType}
        quizType={quizType}
        quizMarksReview={quizMarksReview.current}
        setIsSubmitted={setIsSubmitted}
        isLoading={loading}
      />
      <div>dsadasdasdasbdhjasb</div>
    </div>
  );
}

export default ClassroomHome;
