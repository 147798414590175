import React, { useEffect, useRef, useState } from "react";
import Question1 from "../../../images/1.gif";
import Question2 from "../../../images/2.jpg";
import Question3 from "../../../images/3.gif";
import Question4 from "../../../images/4.gif";
import parse, { domToReact } from "html-react-parser";
import LessonLeftSide from "./LessonLeftSide";
import LessonRightSide from "./LessonRightSide";
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import appContext from "../../../context/appContext";

function LessonCard({
  lessonResponseData,
  selectedResponseButtons,
  setSelectedResponseButtons,
  classId,
  lessondata,
  getSidebarData,
  currentWordIndex,
  setCurrentWordIndex,
  speaking,
  setSpeaking,
  audio,
  setAudio,
  currentIndex,
  setCurrentIndex,
  lessonChanged,
  setLessonChanged,
  showButton,
  setShowButton,
  handleSubmittedStatus,
  addOrUpdateLessonIdWithIndex,
}) {
  const isFirstRender = useRef(true);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(-1);
  const [isSpinning, setIsSpinning] = useState(false);
  const [pauseResumeStatus, setPauseResumeStatus] = useState(true);
  const [chunkSize] = useState(5);
  const shouldHighlight = useRef(true);
  const [pauseCharIndex, setPauseCharIndex] = useState(null);
  const [currentCharIndex, setCurrentCharIndex] = useState(null);
  const isResumedOrPaused = useRef(0);
  const state = useContext(appContext);

  const speechUtteranceRef = useRef(null);
  const navigate = useNavigate();

  const getTextChunks = (text, chunkSize = 3) => {
    const words = text.replace(/(<([^>]+)>)/gi, "").split(/\s+/);
    const chunks = [];

    for (let i = 0; i < words.length; i += chunkSize) {
      chunks.push(words.slice(i, i + chunkSize).join(" "));
    }

    return chunks;
  };

  const handleRepeat = (text) => {
    state.audio.pause();
    window.speechSynthesis.cancel();
    setPauseResumeStatus(true);
    setIsSpinning(true);
    speakText(text); // Your existing function call
  };
  const stripHtmlTags = (text) => {
    text = text.replace(/&nbsp;/g, " ");

    // Convert HTML <br> and <p> tags to newline characters to preserve text formatting
    text = text.replace(/<br\s*\/?>/gi, "\n").replace(/<\/?p>/gi, "\n");

    // Strip other HTML tags, keeping the content intact
    text = text.replace(/<\/?[^>]+(>|$)/g, "");

    // Remove emojis while preserving the rest of the text
    text = text.replace(
      /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F1E0}-\u{1F1FF}\u{2300}-\u{23FF}\u{25A0}-\u{25FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{3000}-\u{303F}]/gu,
      ""
    );

    return text;
  };

  const speakText = async (htmlText) => {
    const plainText = stripHtmlTags(htmlText);
    const segments = plainText.split(" "); // Splitting by words for simplicity
    const segmentDurations = calculateDurations(segments);

    try {
      const response = await fetch(`https://texttospeech.googleapis.com/v1/text:synthesize?key=AIzaSyBkXzwxwTFK9mCy-Lr58WYk9xdXKXdX_c4`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          input: { text: plainText },
          voice: {
            languageCode: "en-GB", // Set to English (UK)
            name: "en-GB-Standard-A",
          },
          audioConfig: { audioEncoding: "MP3" },
        }),
      });

      if (!response.ok) {
        throw new Error("Text-to-speech conversion failed");
      }
      const data = await response.json();
      const audioContent = data.audioContent;
      const audioSrc = `data:audio/mp3;base64,${audioContent}`;
      const audio = new Audio(audioSrc);
      audio.autoplay = true;
      setIsLoading(false);
      setIsSpinning(false);

      state.setAudio(audio);

      audio.onplay = () => {
        speakTextSynthesis(plainText);
        setSpeaking(true);
        // highlightSegments(segments, segmentDurations); // Function to highlight segments
      };
      audio.onended = () => {
        window.speechSynthesis.cancel();
        if (!lessonResponseData[currentIndex].has_next_page && !lessonResponseData[currentIndex].has_button) {
          getSidebarData();
        }
        // Clear highlighting when done
        // setCurrentWordIndex(null);
        setSpeaking(false); // Indicate that speech synthesis has ended
        setCurrentWordIndex(null); // Set to null or another value that indicates no highlighting
        scrollToBottom();
        setShowButton(true);
      };
      audio.play().catch((error) => console.error("Error playing the audio", error));
    } catch (error) {
      console.error("Error calling the Text-to-Speech API", error);
    }
  };
  const speakTextSynthesis = (htmlText) => {
    const plainText = stripHtmlTags(htmlText);
    // Clean the text
    if ("speechSynthesis" in window) {
      const utterance = new SpeechSynthesisUtterance(plainText);
      // Configure the utterance (onstart, onboundary, onend, etc.)
      utterance.volume = 0; // Silent
      utterance.rate = 1.3;

      const voices = window.speechSynthesis.getVoices();
      const selectedVoice = voices.find((voice) => voice.name === "Microsoft George - English (United Kingdom)");
      utterance.voice = selectedVoice;
      window.speechSynthesis.speak(utterance);
      utterance.onstart = () => {
        setSpeaking(true); // Indicate that speech synthesis has started
        setCurrentWordIndex(0); // Start highlighting from the first word
      };
      utterance.onend = () => {
        // setSpeaking(false); // Indicate that speech synthesis has ended
        scrollToBottom();

        if (!lessonResponseData[currentIndex].has_next_page && !lessonResponseData[currentIndex].has_button) {
          getSidebarData();
          handleSubmittedStatus(true);
        }
        setCurrentWordIndex(null); // Set to null or another value that indicates no highlighting

        shouldHighlight.current = true; // Ensure highlighting is enabled when speech ends

        window.speechSynthesis.cancel();
      };
      utterance.onboundary = (event) => {
        if (isResumedOrPaused.current === 0) {
          if (shouldHighlight.current) {
            const textUpToBoundary = stripHtmlTags(lessonResponseData[currentIndex].page_question).slice(0, event.charIndex).trim();
            const wordCountUpToBoundary = textUpToBoundary.split(" ").length;
            setCurrentWordIndex(wordCountUpToBoundary - 1);
            setCurrentCharIndex(event.charIndex); // Update the current character index
          }
        } else {
          if (shouldHighlight.current) {
            const textUpToBoundary = stripHtmlTags(lessonResponseData[currentIndex].page_question)
              .slice(0, event.charIndex - 10)
              .trim();
            const wordCountUpToBoundary = textUpToBoundary.split(" ").length;
            setCurrentWordIndex(wordCountUpToBoundary - 1);
            setCurrentCharIndex(event.charIndex); // Update the current character index
          }
        }
      };
    }
  };

  const calculateDurations = (segments) => {
    // This is a simplified example. You might need a more sophisticated calculation based on your specific needs.
    const averageSpeechRate = 150; // Words per minute
    const minuteInMilliseconds = 60000;
    const averageDurationPerWord = minuteInMilliseconds / averageSpeechRate;
    return segments.map(() => averageDurationPerWord);
  };

  const highlightSegments = (segments, segmentDurations) => {
    let currentTime = 0;

    segments.forEach((segment, index) => {
      setTimeout(() => {
        setCurrentWordIndex(index); // Function to update the state for highlighting the current segment
      }, currentTime);
      currentTime += segmentDurations[index];
    });
  };

  function scrollToBottom() {
    var contentDiv = document.querySelector(".main-content-div"); // Adjust the selector as needed
    contentDiv.scrollTop = contentDiv.scrollHeight;
  }

  function scrollToHighlight(element) {
    var scrollableDiv = document.querySelector(".main-content-div"); // Adjust the selector as needed
    if (scrollableDiv && element) {
      // Check if the element is at the bottom of the scrollable div
      const elementBottom = element.offsetTop + element.offsetHeight;
      const divBottom = scrollableDiv.scrollTop + scrollableDiv.offsetHeight;

      // If the highlighted element is out of view at the bottom, scroll it into view
      if (elementBottom > divBottom) {
        scrollableDiv.scrollTop = elementBottom - scrollableDiv.offsetHeight;
      }
    }
  }

  const renderTextWithHighlighting = () => {
    // Ensure that the current lesson response data is defined
    if (lessonResponseData === undefined) {
      // Return null, an empty string, or some placeholder content when data is not ready
      return "null"; // or "<p>Loading...</p>" or similar
    }

    let wordCount = 0; // Counter for words to identify the current reading position

    const options = {
      replace: ({ type, data }) => {
        if (type === "text") {
          const words = data.split(" ").map((word, index) => {
            const isFirstWord = index === 0;
            const highlightText = word.replace(/[^A-Za-z0-9]/g, "");

            var isHighlighted;
            if (highlightText) {
              isHighlighted = currentWordIndex !== null && wordCount >= currentWordIndex && wordCount < currentWordIndex + chunkSize;
              wordCount++; // Increment wordCount for each word
            }
            scrollToHighlight(document.getElementById("highlight"));
            return (
              <span
                id={isHighlighted && "highlight"}
                key={index}
                style={{
                  backgroundColor: isHighlighted ? "#7E418B" : "transparent",
                }}
              >
                {isFirstWord ? "" : " "}
                {word}
              </span>
            );
          });

          return <>{words}</>;
        }
      },
    };

    // Now safe to access .text property as we've ensured lessonResponseData[currentIndex] is defined
    return parse(String(lessonResponseData[currentIndex].page_question), options);
  };

  const pauseSpeech = () => {
    if (window.speechSynthesis.speaking) {
      shouldHighlight.current = false; // Disable highlighting when speech is paused
      setPauseCharIndex(currentCharIndex); // Assume currentCharIndex is updated in onboundary
      state.audio.pause();
      setPauseResumeStatus(false);
      window.speechSynthesis.pause();
    }
  };
  const resumeSpeech = () => {
    if (window.speechSynthesis.paused) {
      isResumedOrPaused.current = 1;
      shouldHighlight.current = true;
      state.audio.play();
      setPauseResumeStatus(true);
      if (pauseCharIndex !== null) {
        // Calculate the correct word index based on pauseCharIndex
        const textUpToPause = stripHtmlTags(lessonResponseData[currentIndex].page_question).slice(0, pauseCharIndex).trim();
        const wordCountUpToPause = textUpToPause.split(" ").length;
        setCurrentWordIndex(wordCountUpToPause - 4); // Adjust this calculation as needed
      }
      window.speechSynthesis.resume();
    }
  };

  // Effect for initial speech synthesis
  useEffect(() => {
    window.speechSynthesis.cancel();

    setLessonChanged(false);
    const timer = setTimeout(() => {
      if (state.audio) {
        state.audio.pause();
        state.audio.current = 0;
      }
      speakText(lessonResponseData[currentIndex].page_question);
      isFirstRender.current = false;
    }, 500); // Delay of 500ms

    return () => clearTimeout(timer);
  }, [lessonResponseData]);

  const updateLessonStatus = () => {
    console.log("LESSON IN THE UPDATE: ", lessondata[0].lesson_id);
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_lesson_progress", {
        method: "POST",
        lesson_id: lessondata[0].lesson_id,
        class_id: classId,
        area_id: lessondata[0].area_id,
        today_date: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
      })
      .then((res) => {
        console.log("Quiz: ", res.data);
        // getSidebarData();
        // setQuizOrLesson(0);
        // handleQuizValue(res.data.quiz_question_list);
        // handleQuizTotalMarks(quiz.quiz_marks);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Effect for handling changes in currentIndex
  useEffect(() => {
    window.speechSynthesis.cancel();

    if (!lessonChanged) {
      if (!isFirstRender.current) {
        speakText(lessonResponseData[currentIndex].page_question);
      }
    }
  }, [currentIndex]);

  const nextObject = () => {
    window.speechSynthesis.cancel();

    // Reset highlighter state
    setCurrentWordIndex(0);
    // Check if we're not at the end of the array
    // Show loading indicator
    setIsLoading(true);

    // Wait for 2 seconds before updating the currentIndex
    setTimeout(() => {
      // Update the currentIndex
      let selectedLesson;
      var index;
      if (!lessonResponseData[currentIndex].has_next_page && lessonResponseData[currentIndex].has_button) {
        let selectedoption = selectedResponseButtons[selectedAnswerIndex];
        if (selectedoption.target_page_id === null) {
          setIsLoading(false);
          setCurrentWordIndex(-5);
          updateLessonStatus();
          return;
        }
      }
      if (lessonResponseData[currentIndex].has_next_page && !lessonResponseData[currentIndex].has_button) {
        index = lessonResponseData.findIndex((lesson) => lesson.page_id === lessonResponseData[currentIndex].next_page_id);
      } else {
        let selectedoption = selectedResponseButtons[selectedAnswerIndex];
        if (selectedoption.target_page_id !== null) {
          index = lessonResponseData.findIndex((lesson) => selectedoption.target_page_id === lesson.page_id);
        }
      }
      if (index !== undefined) {
        selectedLesson = lessonResponseData[index];
        setSelectedAnswerIndex(-1);
        if (selectedLesson.next_page_id === null && !selectedLesson.has_button) {
          updateLessonStatus();
        }
      }
      if (selectedLesson.has_button) {
        setSelectedResponseButtons(selectedLesson.options_list);
      } else {
        setSelectedResponseButtons([]);
      }
      setCurrentIndex(index);
      addOrUpdateLessonIdWithIndex(selectedLesson.lesson_id, index);
      // Hide loading indicator
      // setIsLoading(false);
    }, 2000); // 2000 milliseconds = 2 seconds
  };

  const previousObject = () => {
    setIsLoading(true);
    window.speechSynthesis.cancel();

    // Reset highlighter state

    // Check if we're not at the end of the array
    // Show loading indicator

    state.audio.pause();

    isResumedOrPaused.current = 0;
    shouldHighlight.current = true;

    setPauseResumeStatus(true);
    state.audio.currentTime = 0;
    setCurrentWordIndex(0);
    var index;
    var selctedPage;

    index = lessonResponseData.findIndex((lesson) => lessonResponseData[currentIndex].page_id === lesson.next_page_id);

    if (index === -1) {
      for (let indexLesson = 0; indexLesson < lessonResponseData.length; indexLesson++) {
        const lesson = lessonResponseData[indexLesson];
        if (lesson.has_button) {
          for (let indexOption = 0; indexOption < lesson.options_list.length; indexOption++) {
            const button = lesson.options_list[indexOption];
            if (button.target_page_id === lessonResponseData[currentIndex].page_id) {
              setCurrentIndex(indexLesson);
              addOrUpdateLessonIdWithIndex(lesson.lesson_id, index);

              setSelectedResponseButtons(lesson.options_list);
            }
          }
        }
      }
    } else {
      setCurrentIndex(index);
      addOrUpdateLessonIdWithIndex(lessonResponseData[currentIndex].lesson_id, index);
      setSelectedResponseButtons([]);
    }
  };

  const handleSelectedAnswerIndex = (index) => {
    setSelectedAnswerIndex(index);
  };

  const handleNavigateToHome = () => {
    setSpeaking(false);
    setCurrentWordIndex(-5);
    if (state.audio) {
      state.audio.pause();
      state.audio.currentTime = 0;
    }
    window.speechSynthesis.cancel();
    navigate("/home");
  };
  return (
    <React.Fragment>
      <section>
        {lessonResponseData !== undefined && (
          <div
            className={`w-auto h-auto grid text-white text-4xl ${
              lessonResponseData[currentIndex]?.image_file_name === null ? "md:grid-cols-1" : "grid-cols-2"
            } overflow-hidden`}
          >
            <LessonLeftSide
              lessonResponseData={lessonResponseData}
              selectedResponseButtons={selectedResponseButtons}
              currentIndex={currentIndex}
              previousObject={previousObject}
              nextObject={nextObject}
              speaking={speaking}
              speakText={speakText}
              isLoading={isLoading}
              pauseSpeech={pauseSpeech}
              resumeSpeech={resumeSpeech}
              selectedAnswerIndex={selectedAnswerIndex}
              handleSelectedAnswerIndex={handleSelectedAnswerIndex}
              isSpinning={isSpinning}
              handleRepeat={handleRepeat}
              pauseResumeStatus={pauseResumeStatus}
              renderTextWithHighlighting={renderTextWithHighlighting}
              handleNavigateToHome={handleNavigateToHome}
              showButton={showButton}
              setShowButton={setShowButton}
            />
            <LessonRightSide lessonResponseData={lessonResponseData} currentIndex={currentIndex} />
          </div>
        )}
      </section>
    </React.Fragment>
    // <div className="app-container">

    //   {/* <QuestionDisplay question={question.text} onSpeak={speakQuestion} />
    //   <MediaDisplay media={question.media} type={question.mediaType} /> */}
    // </div>
  );
}

export default LessonCard;
